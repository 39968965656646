<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "constantes",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "constantes",
      items: [
        {
          text: "Définition",
        },
        {
          text: "constantes",
          active: true,
        },
      ],
      errors: [],
      constante: {},
      constantes: [],
      name: "",
      value: "",
      description: "",
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('constantes'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        
        this.doesLoadingOpen = false
        this.constantes = data
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let body = {
        "name": this.name,
        "description": this.description,
        "value": this.value
      }
      fetch(Helper.route('constantes'), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        
        this.loading = false
        if (this.status == 201) {
          this.doesAddOpen = false
          this.constantes.push(data) 
          Swal.fire("constante ajouter !", "La constante a été enregistrée avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "constante_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let body = {
        "name": this.name,
        "description": this.description,
        "value": this.value
      }
      fetch(Helper.route('constantes/'+this.constante.id), Helper.requestOptionsJSON("PATCH", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.constantes = this.constantes.map(i => i.id !== this.constante.id ? i : data)
          Swal.fire("constante modifier !", "La constante a été modifiée avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "constante_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      fetch(Helper.route('constantes/'+this.constante.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        if (this.status == 200) {
          this.constantes = this.constantes.filter(i => i.id !== this.constante.id)
          this.loading = false
          this.constante = null
          this.doesDeleteOpen = false
          Swal.fire("constante supprimer !", "La constante a été supprimée avec succès.", "success");
        } else {
          Swal.fire("constante non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },
    initItem(constante, action="edit") {
      this.constante = constante
      this.initData(constante)
      if (action == "delete") {
        this.doesDeleteOpen = true
      } else {
        this.doesEditOpen = true
      }
    },
    initData(item = null) {
      this.name = item != null ? item.name : ""
      this.value = item != null ? item.value : ""
      this.description = item != null ? item.description : ""
    }
  },
  mounted() {
    this.loadList()
    this.doesLoadingOpen = false
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
      if (!this.doesAddOpen) {
        this.initData(null)
      }
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.initData(null)
        this.constante = null
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.initData(null)
        this.constante = null
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3">
        <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter un nouveau
        </b-button>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des constantes</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <value>constantes</value>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>constante</th>
                    <th>value</th>
                    <th>description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(constante, i) in constantes" :key="constante.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ constante.name }}</td>
                    <td>{{ constante.value }}</td>
                    <td>{{ constante.description }}</td>
                    <td class="hstack">
                        <b-button class="btn-sm ms-2" @click="initItem(constante, 'edit')" variant="success">
                            <i class="bx bx-edit font-size-16 align-middle mx-1"></i>
                        </b-button>
                        <b-button variant="danger" @click="initItem(constante, 'delete')" class="ms-2 btn-sm">
                          <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                        </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>

    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>

    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        centered
        size="md"
        title="Ajouter un constante"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
              <input type="text" placeholder="Nom du constante" required v-model="name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <input type="text" placeholder="value" required v-model="value" class="form-control">
              <span class="text-danger mt-2" v-if="errors.value">{{ errors.value }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <input type="text" placeholder="description" required v-model="description" class="form-control">
              <span class="text-danger mt-2" v-if="errors.description">{{ errors.description }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        centered
        size="md"
        title="Modifier un constante"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
              <input type="text" placeholder="Nom du constante" required v-model="name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <input type="text" placeholder="value" required v-model="value" class="form-control">
              <span class="text-danger mt-2" v-if="errors.value">{{ errors.value }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <input type="text" placeholder="description" required v-model="description" class="form-control">
              <span class="text-danger mt-2" v-if="errors.description">{{ errors.description }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer un constante"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer le constante <b>{{ constante?.name }}</b> ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


  </Layout>
</template>
